import styled from "styled-components";
import useSWR from "swr";
import { Flex, Box, Text, Button } from "../../../../components";
import dayjs from "dayjs";
import Image from "next/image";
import { useEffect, useState } from "react";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import Heading from "../../../../components/Heading";
import { useRouter } from "next/router";
import Link from "next/link";

const StyledWrapper = styled("div")`
  position: relative;
  margin-top: 120px;
`;

const StyledContainer = styled("div")`
  max-width: 1440px;
  margin: 0 auto;
  width: 90%;
  position: relative;
`;

const StyledText = styled(Text)`
  font-family: "Noto Sans Thai", sans-serif;
`;

const StyledLogoWrapper = styled("div")`
  mix-blend-mode: normal;

  box-shadow: 0px 0px 14px #54e0ff;
  img {
    border-radius: 10px;
  }
  border-radius: 10px;
`;

const StyledTournamentInfo = styled(Flex)`
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
  }
  :not(:last-child):after {
    position: absolute;
    content: " ";
    bottom: -40px;
    height: 0.5px;
    background-color: white;
    width: 100%;
  }
`;

const StyledSelectedArrow = styled("div")`
  position: absolute;
  right: -10%;
`;

const Tournament = () => {
  const { data } = useSWR<{ data: TournamentData[] }>(
    "/v1/tournament?page=1&size=3"
  );
  const tournaments = data?.data || [];
  const [selectedTournament, setSelectedTournament] =
    useState<TournamentData>();

  useEffect(() => {
    if (!selectedTournament && tournaments?.length > 0) {
      setSelectedTournament(tournaments[0]);
    }
  }, [tournaments?.length > 0]);

  // const screens = useBreakpoint();
  return (
    <StyledWrapper>
      <StyledContainer>
        <Heading>TOURNAMENT</Heading>

        <Box mt="120px">
          <Heading fontSize="30px">COMING SOON</Heading>
        </Box>

        {/* <Flex
          alignItems="center"
          justifyContent="space-between"
          mt="63px"
          flexWrap={screens.xs ? "wrap-reverse" : "unset"}
          style={{ gap: "50px" }}
        >
          <Flex
            flexDirection="column"
            style={{ gap: "60px" }}
            flex={screens.xs ? "" : 1}
          >
            {tournaments?.map((item) => (
              <StyledTournamentInfo
                onClick={() => setSelectedTournament(item)}
                key={item.id}
                alignItems="center"
                style={{ gap: "22px" }}
              >
                {item.id === selectedTournament?.id && !screens.xs && (
                  <StyledSelectedArrow>
                    <img src="/images/arrow-right.svg" alt="arrow-right" />
                  </StyledSelectedArrow>
                )}
                <StyledLogoWrapper>
                  <Image src={item.logo} alt="Logo" width={80} height={80} />
                </StyledLogoWrapper>
                <Box>
                  <StyledText
                    fontSize="20px"
                    lineHeight="27px"
                    fontWeight="700"
                  >
                    {item.name}
                  </StyledText>
                  <Text
                    mt="7px"
                    fontSize="14px"
                    lineHeight="19x"
                    fontWeight="300"
                  >
                    {dayjs(item.start_date).format("DD/MM/YYYY")}
                  </Text>
                </Box>
              </StyledTournamentInfo>
            ))}
          </Flex>
          {selectedTournament && (
            <Flex flex={screens.xs ? "" : 1}>
              <img
                alt="Banner"
                src={selectedTournament.banner}
                style={{ maxWidth: "100%" }}
              />
            </Flex>
          )}
        </Flex>
        <Flex position="relative" justifyContent="center" mt="70px" zIndex={2}>
          <Button
            onClick={() => router.push("/tournament", "", { shallow: true })}
          >
            VIEW ALL
          </Button>
        </Flex> */}
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Tournament;
