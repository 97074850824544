import React from "react";
import { Flex, Box, Text } from "../../../../components";
import styled from "styled-components";
import Image from "next/image";
import ClockIconImage from "public/images/clock.svg";
import { useRouter } from "next/router";
import dayjs from "dayjs";

interface EventInfoProps {
  eventId: number;
  date: string;
  eventName: string;
  time: string;
  month: string;
  maxWidth?: string;
  imageUrl?: any;
}

const DateCardWrapper = styled("div")`
  background-color: rgba(255, 255, 255, 0.26);
  min-height: 50px;
  min-width: 50px;
  border-radius: 10px;
  mix-blend-mode: screen;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 80px;
    width: 80px;
    box-shadow: 0px 0px 14px #54e0ff;
    padding: 12px;
  }
`;

const StyledText = styled(Text)`
  font-family: "Noto Sans Thai", sans-serif;
`;

const StyledCard = styled(Box)`
  cursor: pointer;
  overflow: hidden;
  position: relative;
`;

const ContentBackground = styled("div")`
  ${({ theme }) => theme.mediaQueries.md} {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 29, 62, 0) -10%, #001d3e 100%);
    bottom: 0;
    left: 0;
    right: 0;
    height: 112px;
    width: 100%;
    z-index: 1;
  }
`;

const StyledCardContainer = styled("div")`
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.05);
  }
`;

const StyledImageContainer = styled("div")`
  width: 100%;
  img {
    object-fit: cover !important;
    width: 100% !important;
    position: relative !important;
    height: 194px !important;

    ${({ theme }) => theme.mediaQueries.md} {
      height: 342px !important;
    }
  }
`;

const StyledFlex = styled(Flex)`
  margin-top: 16px;
  ${({ theme }) => theme.mediaQueries.md} {
    position: absolute;
    gap: 12px;
    bottom: 20px;
    left: 26px;
    z-index: 2;
  }
`;

const StyledBox = styled(Box)`
  max-width: 80%;
  margin-left: 10px;
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 58%;
    margin: 0;
  }
`;

const EventInfo: React.FC<{ item: EventData }> = ({ item }) => {
  const router = useRouter();
  return (
    <StyledCard
      style={{ position: "relative" }}
      onClick={() => router.push(`/events/${item.id}`)}
    >
      <StyledCardContainer>
        <ContentBackground />
        <StyledImageContainer>
          <Image src={item?.logo} alt="Event 1" fill />
        </StyledImageContainer>

        <StyledFlex alignItems="center">
          <DateCardWrapper>
            <StyledText
              fontWeight="700"
              fontSize="16px"
              lineHeight="22px"
              ellipsis
            >
              {dayjs(item.start_date).format("DD")}
            </StyledText>
            <StyledText fontWeight="700" fontSize="16px" lineHeight="21px">
              {dayjs(item.start_date).format("MMM")}
            </StyledText>
          </DateCardWrapper>
          <StyledBox>
            <StyledText
              fontWeight="700"
              fontSize="20px"
              lineHeight="27px"
              ellipsis
            >
              {item.name}
            </StyledText>
            <Flex alignItems="center" mt="6px">
              <Image src={ClockIconImage} alt="Clock" width={18} />
              <StyledText fontWeight="300" fontSize="14px" lineHeight="19px">
                {dayjs(item.start_date).format("HH.mm A")} -
                {dayjs(item.end_date).format("HH.mm A")}
              </StyledText>
            </Flex>
          </StyledBox>
        </StyledFlex>
      </StyledCardContainer>
    </StyledCard>
  );
};

export default EventInfo;
