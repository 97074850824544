import LandingPage from '../modules/LandingPage'
import axios from "axios";
import { API_BASE_URL } from "../config/constants/endpoints";

function Home() {
  return <LandingPage />;
}

export const getStaticProps = async () => {
  const [
    { data: newsResponse },
    { data: eventsResponse },
    { data: tournamentsResponse },
  ] = await Promise.all([
    axios.get(`/v1/news?page=1&size=3&is_highlight=true`, {
      baseURL: API_BASE_URL,
    }),
    axios.get(`/v1/event?page=1&size=3`, {
      baseURL: API_BASE_URL,
    }),
    axios.get("/v1/tournament?page=1&size=3", {
      baseURL: API_BASE_URL,
    }),
  ]);
  return {
    props: {
      fallback: {
        [`/v1/news?page=1&size=3`]: newsResponse,
        [`/v1/event?page=1&size=3`]: eventsResponse,
        [`/v1/tournament?page=1&size=3`]: tournamentsResponse,
      },
    },
    revalidate: 300, // 5 minutes
  };
};

export default Home;

