import Hero from "./components/Hero";
import MainLayout from "../../layouts/main";
import Highlight from "./components/Highlight";
import UpcomingEvent from "./components/UpcomingEvent";
import Tournament from "./components/Tournament";

const IndexPage = () => {
  return (
    <MainLayout>
      <Hero />
      <Highlight />
      <UpcomingEvent />
      <Tournament />
    </MainLayout>
  );
};

export default IndexPage;
