import styled from "styled-components";
import Image from "next/image";
import Line1Image from "public/images/event-line-1.svg";
import Line2Image from "public/images/event-line-2.svg";
import { Grid, Box, Flex, Button } from "../../../../components";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import useSWR from "swr";
import Heading from "../../../../components/Heading";
import { useRouter } from "next/router";
import { Text } from "../../../../components";
import EventInfo from "./EventInfo";

const StyledWrapper = styled("div")`
  position: relative;
  margin-top: 134px;
  img {
    max-width: 100%;
    object-fit: cover;
  }
`;

const StyledContainer = styled("div")`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  width: 90%;
`;

const StyledEventLine1Wrapper = styled("div")`
  position: absolute;
  top: 15%;
  left: -5%;
`;

const StyledEventLine2Wrapper = styled("div")`
  position: absolute;
  right: -4%;
  bottom: -17%;
`;

const StyledLightLeft = styled("img")`
  position: absolute;
  left: -2%;
  top: 17%;
`;

const StyledLightRight = styled("img")`
  position: absolute;
  right: -2%;
  bottom: 24%;
`;

const StyledText = styled(Text)`
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-shadow: 0px 0px 4px #9acaf4;

  font-size: 24px;
  line-height: 30px;
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 40px;
    line-height: 50px;
  }
`;

const StyledGrid = styled(Grid)`
  margin-top: 50px;
  grid-template-columns: 1fr;
  gap: 26.3px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 95px;
    grid-template-columns: 2fr 1fr 1fr;
  }
`;

const StyledFlex = styled(Flex)`
  margin-top: 94px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 69px;
  }
`;

const UpcomingEvent = () => {
  const screens = useBreakpoint();
  const { data } = useSWR<{ data: EventData[] }>("/v1/event?page=1&size=3");
  const events = data?.data || [];
  const router = useRouter();
  return (
    <StyledWrapper>
      <StyledContainer>
        {!screens.xs && (
          <StyledLightLeft src="/images/event-light-left.svg" alt="light" />
        )}

        {events?.length >= 3 && !screens.xs && (
          <StyledLightRight src="/images/event-light-right.svg" alt="light" />
        )}

        <Flex justifyContent="center">
          <StyledText>UPCOMING EVENT</StyledText>
        </Flex>
        <StyledGrid>
          {events?.map((item) => (
            <EventInfo key={item.id} item={item} />
          ))}
        </StyledGrid>
        <StyledFlex position="relative" justifyContent="center" zIndex={2}>
          <Button onClick={() => router.push("/events")}>VIEW ALL</Button>
        </StyledFlex>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default UpcomingEvent;
