import styled, { keyframes } from "styled-components";
import { Grid, Flex, Text, Button, NewsInfo } from "../../../components";
import Image from "next/image";
import useBreakpoint from "../../../hooks/useBreakpoint";
import useSWR from "swr";
import Heading from "../../../components/Heading";
import { useRouter } from "next/router";
import { useMemo } from "react";
import dayjs from "dayjs";

const StyledWrapper = styled("div")`
  position: relative;
`;

const StyledContainer = styled("div")`
  max-width: 1440px;
  margin: 0 auto;
  width: 90%;
  position: relative;
`;

const blink = keyframes`
  0%  {
    color: rgba(255,255,255,0.4);
  }
  50% {
    color: rgba(255,255,255,1);
  }
  100% {
    color: rgba(255,255,255,0.4);
  }
`;

const StyledText = styled(Text)`
  font-family: "Noto Sans Thai", sans-serif;
`;

const StyledDigitalHubText = styled(Text)`
  position: absolute;
  letter-spacing: 0.15em;
  color: #ffd600;
  font-weight: 900;
  font-size: 152px;
  z-index: 1;
  opacity: 0.1;
  bottom: 0;
  left: 0%;
  line-height: 191px;
  white-space: nowrap;
`;

const StyledLightImageWrapper = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledCard = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.2);
  }
`;

const StyledLightLeft = styled("img")`
  position: absolute;
  left: -6%;
  top: 1%;
`;

const StyledLightRight = styled("img")`
  position: absolute;
  right: -6%;
  top: 1%;
`;

const Highlight = () => {
  const screens = useBreakpoint();
  const { data } = useSWR<{ data: News[] }>(
    "/v1/news?page=1&size=3&is_highlight=true"
  );
  const news = data?.data || [];
  const router = useRouter();
  const filteredNews = useMemo(() => {
    return news?.filter((item) => {
      const startDate = dayjs(item.start_date);
      const endDate = dayjs(item.end_date);
      const currentDate = dayjs();
      return (
        startDate.isBefore(currentDate, "day") &&
        endDate.isAfter(currentDate, "day")
      );
    });
  }, [news]);

  return (
    <StyledWrapper>
      <StyledContainer>
        {!screens.xs && (
          <>
            <StyledLightLeft
              src="/images/highlight-light-left.svg"
              alt="light-left"
            />
            <StyledLightRight
              src="/images/highlight-light-right.svg"
              alt="light-left"
            />
            {/* <Image src={LightImage} alt="light" /> */}
          </>
        )}
        {!screens.xs && (
          <StyledDigitalHubText>DIGITAL HUB</StyledDigitalHubText>
        )}
        <Heading>NEWS Highlight</Heading>

        <Grid
          gridTemplateColumns={screens.xs ? "1fr" : "1fr 1fr 1fr"}
          style={{ gap: "75px" }}
          mt="60px"
        >
          {filteredNews?.map((item, index) => (
            <NewsInfo key={item.id} item={item} index={index} />
          ))}
        </Grid>
        <Flex
          position="relative"
          justifyContent="center"
          mt="67px"
          style={{ zIndex: "2" }}
        >
          <Button onClick={() => router.push("/news")}>VIEW ALL</Button>
        </Flex>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Highlight;
