import styled from "styled-components";
import { Text } from "../../../components/Text";

const StyledWrapper = styled("div")`
  width: 100%;
  position: relative;
`;

const StyledContent = styled("div")`
  max-width: 1440px;
  margin: 0 auto;
  padding: 30px; 0;
  width: 90%;
`;

const StyledTitle = styled("h1")`
  color: white;
  text-align: center;
  width: 100%;
  margin: 220px 0;
  p:nth-child(1) {
    font-size: 42px;
    font-weight: 700;
  }
  p:nth-child(2) {
    font-size: 101px;
    font-weight: 900;
  }
`;

const StyledAction = styled("div")`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items; center;
  justify-content: center;
  font-weight: 300;
  font-size: 22px;
  letter-spacing: 0.2em;
`;

const Hero = () => {
  return (
    <StyledWrapper>
      <StyledContent>
        <StyledTitle>
          <p>WELCOME TO</p>
          <p>PRO HUB</p>
          <StyledAction>
            <Text>Explore</Text>
            <Text>|</Text>
            <Text>Learn</Text>
            <Text>|</Text>
            <Text>Play</Text>
            <Text>|</Text>
            <Text>Enjoy</Text>
          </StyledAction>
        </StyledTitle>
      </StyledContent>
    </StyledWrapper>
  );
};

export default Hero;
